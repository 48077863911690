<template>
  <Page :title="$t('widgets.inviteFriend.title')">
    <SectionContainer class="l-inviteFriend">
      <BaseButton
        :to="$i18nRoute({name: PROFILE})"
        icon="prev"
        icon-width="14"
        :btn-label="$t('common.ctaLabels.back')"
        color="muted"
      />
      <div class="h-pv-10">
        <Typography
          v-text="$t('widgets.inviteFriend.title')"
          variant="subtitle1"
        />
        <Typography
          v-text="$t('widgets.inviteFriend.subtitle')"
          variant="body1"
        />
      </div>

      <div class="l-inviteFriend__grid">
        <BaseContainer
          bg-color="02"
          class="c-inviteFriend"
        >
          <Typography
            v-text="$t('widgets.inviteFriend.title')"
            variant="subtitle1"
          />
          <form
            @submit.prevent="handleSubmit"
            @invalid.capture.prevent="validate"
            class="c-inviteFriendForm"
          >
            <BaseTextBox
              required="true"
              class="c-inviteFriendForm__input"
              :placeholder="$t('widgets.inviteFriend.fields.email.placeholder')"
              :label="$t('widgets.inviteFriend.fields.email.label')"
              name="email"
              id="email"
              :ref="el => { inputEl['email'] = el }"
              v-model="userFriend"
            />
            <BaseButton
              class="c-inviteFriendForm__cta"
              type="submit"
              variant="solid"
              color="primary"
              :btn-label="$t('common.ctaLabels.invite')"
            />
          </form>
        <!--        <form
          @submit.prevent="handleSubmit"
          @invalid.capture.prevent="validate"
          class="c-account__form"
        >
          <BaseTextBox
            :label="'profile.fields.name.label'"
            required="true"
            :placeholder="'profile.fields.name.placeholder'"
            id="name"
            name="name"
            type="text"
            v-model="form.name"
            :ref="el => { inputEl['name'] = el }"
          />
        </form>-->
        </BaseContainer>
        <BaseContainer
          bg-color="02"
          class="c-inviteFriend"
        >
          <Typography
            v-text="$t('widgets.inviteFriend.list.title')"
            variant="subtitle1"
          />
          <table class="c-inviteFriendTable">
            <thead>
              <tr>
                <th>{{ $t('widgets.inviteFriend.list.data') }}</th>
                <th>{{ $t('widgets.inviteFriend.list.friend') }}</th>
                <th>{{ $t('widgets.inviteFriend.list.register') }}</th>
                <th>{{ $t('widgets.inviteFriend.list.firstOrder') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="friend in userFriendsList"
                :key="friend"
              >
                <td>{{ friend.date }}</td>
                <td>{{ friend.email }}</td>
                <td class="c-inviteFriendTable__center">
                  <Icon
                    :data="require(`@icons/${friend.registered ? 'check' : 'close'}.svg`)"
                    :color="`var(--st-${friend.registered ? 'success' : 'error'})`"
                  />
                </td>
                <td class="c-inviteFriendTable__center">
                  <Icon
                    :data="require(`@icons/${friend.order ? 'check' : 'close'}.svg`)"
                    :color="`var(--st-${friend.order ? 'success' : 'error'})`"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </BaseContainer>
      </div>
    </SectionContainer>
  </Page>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue';

import { apiServices } from '@/api/services';
import Page from '@/components/Page.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import SectionContainer from '@/components/common/SectionContainer.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';
import { toast } from '@/toast-notification';

const userFriend = ref('');
const userFriendsList = ref('');
const inputEl = ref([]);
const { PROFILE } = inject('routeNames');

const validate = evt => {
  inputEl.value[evt.target.id].validateForm(evt);
};

const handleSubmit = () => {
  apiServices.inviteFriend(userFriend.value)
    .then(r => {
      if (r) {
        toast.success(r.message);
        userFriendsList.value = r.list;
      }
    });
};

onMounted(() => {
  apiServices.getCustomerInvitationsList()
    .then(r => {
      userFriendsList.value = r;
    });
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-inviteFriend {
  padding-top: 100px;
  padding-bottom: 135px;

  &__grid {
    display: grid;
    grid-gap: 20px;

    @include mx.bp('tablet') {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.c-inviteFriend {
  padding: 20px;
}

.c-inviteFriendTable {
  width: 100%;
  padding: 6px;

  thead th {
    padding: 10px 0;

    text-align: left;

    border-bottom: 1px solid lightgray;
  }

  tbody {
    margin-top: 10px;

    tr {
      &:nth-child(even) {
        background-color: transparentize(v.$body-color-light, 0.9);
      }

      td {
        padding: 5px 0;
      }
    }
  }

  &__center {
    text-align: center;
  }
}
</style>
